.image-hover-container {
  position: relative;
  width: 100%;
}

.image-hover {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .3s ease;
  backface-visibility: hidden;
}

.image-hover-middle {
  transition: .3s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.image-hover-container:hover .image-hover {
  opacity: 0.3;
}

.image-hover-container:hover .image-hover-middle {
  opacity: 1;
}

.image-hover-text {
  font-size: 16px;
  padding: 16px 32px;
}