.spt-item-masa{
    position: absolute;
    font-size: 17px;
}

.spt-item-bulanan{
    position: absolute;
    font-size: 12px;
}

@media print{
    .spt-masa-box{
        margin:0px;
        padding:0px;
    }
}