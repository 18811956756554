.mb-1{
    margin-bottom: 5px
}
.mb-2{
    margin-bottom: 10px
}
.mb-3{
    margin-bottom: 15px
}
.mb-4{
    margin-bottom: 20px
}
.mb-5{
    margin-bottom: 25px
}

.mt-1{
    margin-top: 5px
}
.mt-2{
    margin-top: 10px
}
.mt-3{
    margin-top: 15px
}
.mt-4{
    margin-top: 20px
}
.mt-5{
    margin-top: 25px
}

.w-100{
    width:'100%'
}

.p-1{
    padding:5px
}
.p-2{
    padding:10px
}
.p-3{
    padding:15px
}
.p-4{
    padding:20px
}
.p-5{
    padding:25px
}

.pt-1{
    padding-top:5px
}
.pt-2{
    padding-top:10px
}
.pt-3{
    padding-top:15px
}
.pt-4{
    padding-top:20px
}
.pt-5{
    padding-top:25px
}