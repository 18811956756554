.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.text-center {
  text-align: center;
}

.justify-center {
  vertical-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-top {
  vertical-align: top;
}

.text-bold {
  font-weight: bold;
}

.content-container {
  min-height: calc(100vh - 160px);
  border-radius: 15px
}

.text-muted {
  color: #808080;
}

.app-logo {
  font-size: 20px;
  color: #000;
  float: left;
  min-width: 50px;
  padding-left: 20px;
}

.dropdown-notification-box {
  width: 350px;
  padding: 10px;
  padding-bottom: 20px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ededed;
  border-radius: 10px;
  background-color: #fefefe;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.dropdown-profile-box {
  width: 300px;
  padding: 5px;
  padding-bottom: 20px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ededed;
  border-radius: 10px;
  background-color: #fefefe;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.dropdown-extension-box {
  width: 350px;
  padding: 2px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid #ededed;
  border-radius: 5px;
  background-color: #fefefe;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  bottom: 8.4%;
  right: 1.6%;
}

.card {
  border-radius: 10px;
}

.box-shadow {
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.box-shadow-4 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.box-shadow-3 {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 5px 0 rgba(0, 0, 0, 0.19);
}

.sider-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

.box-shadow-2 {
  border-radius: 15px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 5px 0 rgba(0, 0, 0, 0.19); */
}

.table-collapse {
  border-collapse: collapse;
  border: 1px solid #ededed;
}

.table-default th,
.table-default td {
  padding: 10px;
}

.table-bottomborder th,
.table-bottomborder td {
  border-bottom: 1px solid #ededed;
}

.table-noborder th,
.table-noborder td {
  border: 0px;
}

.table-item th,
.table-item td {
  padding: 5px;
  vertical-align: top;
}

.table-collapse th,
.table-collapse td {
  border-collapse: collapse;
  border: 1px solid #ededed;
  border-radius: 5px;
}

.table-payroll th,
.table-payroll td {
  padding: 15px;
  text-align: center;
}

.table-period-title {
  background-color: #eff2f5;
}

.profile-item-box {
  padding: 10px;
}

.page-breadcrumb {
  margin-bottom: 10px;
}

.directory-item {
  min-height: 230px;
  text-align: center;
  margin: 10px;
}

.directory-division {
  padding-left: 10px;
}

.directory-item-image {
  border-radius: 50% !important;
  height: 6rem !important;
  width: 6rem !important;
}

.directory-item-detail {
  line-height: 1.2rem;
}

.directory-item-modal-box {
  width: 100%;
}

.directory-item-modal-image {
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
  margin-bottom: 10px;
}

.attendance-table {
  pointer-events: none;
}

.attendance-action {
  pointer-events: auto;
}

.attendance-default {
  background-color: #bdbdbd;
}

.attendance-present {
  background: #2efe64;
}

.attendance-late {
  background-color: #f78181;
}

.attendance-leave {
  background-color: #e74c3c;
}

.attendance-absent {
  background-color: #000000;
  color: #bf0900;
  font-weight: 500;
}

.attendance-wfh {
  background-color: #000000;
  color: #ffffff;
  font-weight: 500;
}

.bg-light-grey {
  background-color: #fafafa;
}

/* hide year view */
.ant-radio-group {
  display: none !important;
}

.ant-picker-range {
  width: 100%;
}

.submission-tab .ant-tabs-tab {
  width: 180px;
}

.blinking-badge .ant-scroll-number {
  -webkit-animation: blink 2s infinite;
  -moz-animation: blink 2s infinite;
  -o-animation: blink 2s infinite;
  animation: blink 2s infinite;
}

.menu-text {
  color: #ffffff;
}

.menu-text:hover {
  color: #ffffff !important;
}

.menu-text:visited {
  color: #ffffff !important;
}

.menu-text:active {
  color: #ffffff !important;
}

.menu-text:visited {
  background-color: #ffffff !important;
}

.sider-submenu {
  background-color: #2980b9;
  color: #ffffff;
  margin: 0px !important;
  padding-top: 0px;
}

.sider-submenu:hover {
  background-color: #034a98 !important;
}

.instruction-box {
  background-image: linear-gradient(90deg, #004995, #1c97bf);
  color: #fff !important;
  padding: 10px;
  border-radius: 10px;
}

.ant-menu-submenu {
  margin: 0px !important;
  padding: 0px;
}

.ant-menu-item-selected {
  background-color: #034a98 !important;
  border-left: 3px solid #fff !important;
}

.ant-menu-item {
  margin: 0px !important;
}

.ant-menu-submenu-title {
  margin: 0px !important;
}

.ant-menu-submenu-active {
  background-color: #034a98 !important;
  border-left: 3px solid #fff !important;
  color: #ffffff !important;
}

.ant-menu-submenu-open {
  background-color: #034a98 !important;
  border-left: 3px solid #fff !important;
  color: #ffffff !important;
}

.ant-menu-submenu-selected {
  background-color: #034a98 !important;
  border-left: 3px solid #fff !important;
  color: #ffffff !important;
}


.sub-menu-text {
  color: #ffffff !important;
}

.ant-layout-sider-trigger {
  background-color: #3695e6 !important;
}

.ant-layout-sider-trigger:hover {
  background-color: #004995 !important;
}

.ant-menu-item-only-child.expand-control-active {
  background-color: rgba(39, 128, 211, 0) !important;
  /*Overriden property*/
}

.ant-menu-item-only-child.expand-control {
  background-color: rgba(39, 128, 211, 0) !important;
  /*Overriden property*/
}

@-webkit-keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.scrollbar-custom::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollbar-custom::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border: 0px none #c2c2c2;
  border-radius: 50px;
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

.scrollbar-custom::-webkit-scrollbar-thumb:active {
  background: #a1a1a1;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #e3e1e1;
  border: 0px none #e3e1e1;
  border-radius: 50px;
}

.scrollbar-custom::-webkit-scrollbar-track:hover {
  background: #e3e1e1;
}

.scrollbar-custom::-webkit-scrollbar-track:active {
  background: #e3e1e1;
}

.scrollbar-custom::-webkit-scrollbar-corner {
  background: transparent;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  max-width: 2000px;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #f1c40f;
  color: #000000;
  text-align: center;
}

.styled-table th,
.styled-table td {
  padding: 10px 15px;
}

.styled-table tbody tr {
  font-size: 14px;
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #080808;
}

.styled-table tbody tr.active-row {
  color: #0c0c0c;
}

.color-table-row {
  background-color: rgba(0, 0, 0, 0.089);
  /* border: 1px solid black; */
}

.color-table-overdue {
  background-color: #e84118;
  /* border: 1px solid #e84118; */
}

.color-table-done {
  background-color: #4cd137;
  /* border: 1px solid #4cd137; */
}

.color-table-progress {
  background-color: #fed330;
  /* border: 1px solid #fbc531; */
}

.button-overflow-bottom {
  position: fixed;
  z-index: 1000000;
  bottom: 20px !important;
  right: 50%;
  left: 50%;
}

.action-plan-button {
  width: 320;
  font-size: 11;
  font-weight: bold;
}

.custom-box-shadow {
  box-shadow: -1px 5px 10px -2px rgba(105, 105, 105, 0.82);
  -webkit-box-shadow: -1px 5px 10px -2px rgba(105, 105, 105, 0.82);
  -moz-box-shadow: -1px 5px 10px -2px rgba(105, 105, 105, 0.82);
}

.scrollButton {
  padding: 10px 10px;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 2px;
  background-color: none;
  overflow: auto;
  white-space: nowrap;
}

.scrollButton::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollButton::-webkit-scrollbar-button {
  width: 5px;
  height: 5px;
}

.scrollButton::-webkit-scrollbar-thumb {
  background: #ababab;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.scrollButton::-webkit-scrollbar-thumb:hover {
  background: #949494;
}

.scrollButton::-webkit-scrollbar-thumb:active {
  background: #adadad;
}

.scrollButton::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 50px;
}

.scrollButton::-webkit-scrollbar-track:hover {
  background: #ffffff;
}

.scrollButton::-webkit-scrollbar-track:active {
  background: #ffffff;
}

.scrollButton::-webkit-scrollbar-corner {
  background: transparent;
}

.table-sub {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 1px;
  border-color: #ededed;
  border-style: solid;
  color: #000000;

}

.table-sub td,
.table-sub th {
  border-width: 1px;
  border-color: #ededed;
  border-style: solid;
  padding: 10px;
}

.table-sub thead {
  background-color: #f5f5f5;
}

.ant-table-thead>tr>th {
  color: #fff !important;
  background: #004995 !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  background: #004995 !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label .ant-typography {
  color: #fff !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label .ant-typography {
  color: #fff !important;
}

.ant-card-body .table-collapse th {
  /* color: #fff !important; */
  background: #f5f6fa !important;
  border-radius: 0px !important;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 2px !important;
}

.ant-tabs-tab {
  padding: 12px 12px !important;
  background: #f5f6fa !important;
  border-radius: 5px !important;
}
.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-right>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
  margin: 2px !important;
}