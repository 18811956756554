.btn-dept {
    width: 320;
    font-size: 11;
    font-weight: bold;
    background-color: #4cd137;
    border: #4cd137;
}

.btn-dept:hover {
    width: 320;
    font-size: 11;
    font-weight: bold;
    background-color: #4cd137;
    border: #4cd137;

}

.btn-active-dashboard {
    width: 320;
    font-size: 11;
    font-weight: bold;
    background-color: #318025;
    border: #318025;
}

.card-click {
    padding: 5px 5px 25px 5px;
    cursor: pointer;
}

.card-click:hover {
    background-color: #e0e0e0;
}

.card-click.container {
    width: 100%; 
    background-color: #fff;
}

.card-click.container:hover {
    background-color: #e0e0e0;
}